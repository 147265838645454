<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @on-complete="wizardComplete" @on-change="onChange">
          <template slot="header">
            <h3 class="card-title">Create a new Patient/Lead</h3>
            <h3 class="description">This information will let us know more about the new Patient.</h3>
            <a class="btn btn-link btn-sm" @click="openModal()">Click here to upload in bulk</a>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              Step One
            </template>
            <first-step ref="step1" @on-validated="onStepValidated"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              Step Two
            </template>
            <second-step ref="step2" @on-validated="wizardComplete"></second-step>
          </wizard-tab>

        </wizard>
      </div>
    </div>
    <modal
      :show.sync="modals.upload"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Patients/Leads Bulk Upload</h5>
      <form>
        <div class="row">

          <div class="col-12">
            <div class="picture-container text-center">
                <el-upload
                  drag
                  ref="imageUpload"
                  class="picture"
                  :action="upload_url"
                  :auto-upload="true"
                  :show-file-list="true"
                  list-type="text"
                  name="upload"
                  :headers="upload_headers"
                  :on-success="handleSuccess"
                  :on-remove="handleRemove"
                  accept=".csv"
                  :before-upload="handleBeforeUpload"
                  :on-error="handleError"
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                <div class="el-upload__tip" slot="tip">CSV files with a size less than 1Mb <br> Click here to <a :href="template_url" download>download template</a>.</div>
                <!-- <img v-if="model.imageURL" :src="model.imageURL" class="picture-src"> -->
              </el-upload>
            </div>
          </div>

        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate()" v-if="model.image">Save Record</p-button>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { Modal } from "src/components/UIComponents";
import { Progress, Upload } from "element-ui";

import FirstStep from "./WizardSteps/FirstStep.vue";
import SecondStep from "./WizardSteps/SecondStep.vue";
import swal from "sweetalert2";

import { Wizard, WizardTab } from "src/components/UIComponents";

export default {
  data() {
    return {
      wizardModel: {},
      modals: {
        upload: false,
      },
      model: {
        uploadURL: null,
      }
    };
  },
  components: {
    FirstStep,
    SecondStep,
    Wizard,
    WizardTab,
    Modal,
    [Upload.name]: Upload,
    [Progress.name]: Progress,
  },
  computed: {
    template_url(){
      return `${process.env.VUE_APP_BASE_URL}/templates/patient_upload_template.csv`
    },
    upload_url(){
      return `${process.env.VUE_APP_API_URL}/auth/patients/upload`
    },
    upload_headers(){
      let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
      return { 'Authorization': `Bearer ${authToken}`, 'Accept': 'application/json' };
    },
  },
  methods: {
    handleBeforeUpload(){
      this.modals.upload = false;
      this.$store.commit('setLoader', true);
    },
    handleSuccess(response, file, fileList) {
      this.$store.commit('setLoader', false);
      Vue.prototype.$notify({
        message: response.message,
        icon: 'fa fa-check',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'success'
      })
    },
    handleError(err, file, fileList){
      console.log(err.message);
      
      this.$store.commit('setLoader', false);
      Vue.prototype.$notify({
        message: err.message,
        icon: 'fa fa-bell',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      })
    },
    handleRemove(file, fileList){
      this.model.uploadURL = "";
    },
    openModal(){
      this.model.uploadURL = "";
      this.modals.upload = true;
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };

      if(typeof(this.wizardModel.known_diseases) != 'string')
        this.wizardModel.known_diseases = this.wizardModel.known_diseases.join(',');

      if(typeof(this.wizardModel.preffered_language) != 'string')
        this.wizardModel.preffered_language = this.wizardModel.preffered_language.join(',');

      if(validated)   
      this.$store.dispatch("createPatient", this.wizardModel);
    },
    onChange(prevIndex, nextIndex) {
      console.log("prev", prevIndex, "next", nextIndex);
    }
  }
};
</script>
